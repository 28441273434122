/* You can add global styles to this file, and also import other style files */

// Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/css/font-awesome.css";
// regular style toast
@import '~ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "styles-custom";
