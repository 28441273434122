body {
  font-family: Roboto, serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.pointer {
  cursor: pointer;
}

div.ng-select-container {
  border: none !important;
}

.col {
  min-width: inherit;
}

.ng-invalid {
  @extend .is-invalid;
}

.ng-valid.ng-touched {
  @extend .is-valid;
}

.flex-even {
  flex-grow: 1;
}
